import {inject, Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {ApiAuthCheckOutPost} from "./interface";
import {HttpClient} from "@angular/common/http";

@Injectable({providedIn: "root"})
export class ApiAuthCheckService {
    private httpClient = inject(HttpClient);

    /**
     *
     * @returns {Observable<ApiAuthCheckOutPost>}
     */
    post(): Observable<ApiAuthCheckOutPost> {
        return this.httpClient.post<ApiAuthCheckOutPost>("ma/site/v1/auth/check", {});
    }
}
