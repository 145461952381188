import {ErrorHandler, Injectable} from "@angular/core";
import {PlatformService} from "../service/platform";
import {throwError} from "rxjs";
import * as Sentry from "@sentry/angular";

@Injectable()
export class ErrorHandlerService implements ErrorHandler {
    constructor(private _platform: PlatformService) {}

    handleError(error: Error) {
        if (error.message) {
            /**
             * Остановку рендинга при редиректе пришлось делать костылем по этом и скрывается ошибка
             */
            if (error.message.indexOf("Error: Uncaught (in promise): redirect") === -1) {
                // console.error(`ERROR ${error}`);
            }

            if (this._platform.browser) {
                if (error.message.indexOf("Loading chunk") !== -1) {
                    window.location.reload();
                    return;
                }
            }
            // eslint-disable-next-line no-console
            console.error(error);
        }

        Sentry.captureException(error);
        return throwError(() => error);
    }
}
