import {ChangeDetectionStrategy, Component} from "@angular/core";
import {RouterOutlet} from "@angular/router";
import {UiSmartBannerModule} from "@shared/module_v2/ui/smart-banner/smart-banner.module";
import {LayoutHeaderFloatingModule} from "@shared/module_v2/layout/header-floating/header-floating.module";
import {LayoutHeaderModule} from "@shared/module_v2/layout/header/header.module";
import {UiFloatingBlockBottomModule} from "@shared/module_v2/ui/floating-block-bottom/floating-block-bottom.module";
import {UiScrollTopModule} from "@shared/module_v2/ui/scroll-top/scroll-top.module";
import {UiAlphabetComponent} from "@shared/module_v2/ui/alphabet/alphabet.component";
import {SmsInstallationComponent} from "@shared/module_v2/ui/sms-installation/sms-installation.component";
import {FooterComponent} from "@shared/module_v2/layout/footer/footer.component";

@Component({
    standalone: true,
    imports: [
        UiSmartBannerModule,
        LayoutHeaderFloatingModule,
        LayoutHeaderModule,
        RouterOutlet,
        UiFloatingBlockBottomModule,
        UiScrollTopModule,
        UiAlphabetComponent,
        SmsInstallationComponent,
        FooterComponent,
    ],
    templateUrl: "./main.component.html",
    styleUrls: ["./main.component.less"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainComponent {}
