import {APP_INITIALIZER} from "@angular/core";
import {LoadCanonicalService} from "@common/core/load/canonical.service";
import {CityLoadService} from "@core/service/city/city-load.service";

/**
 * Только для общих сервисов которые нужно загрузить при инициализации приложения.
 */
export const APP_INITIALIZER_GENERAL = [
    {
        provide: APP_INITIALIZER,
        useFactory: (_loadService: CityLoadService) => () => _loadService.load(),
        deps: [CityLoadService],
        multi: true,
    },
    {
        provide: APP_INITIALIZER,
        useFactory: (loadCanonicalService: LoadCanonicalService) => () => loadCanonicalService.load(),
        deps: [LoadCanonicalService],
        multi: true,
    },
];
