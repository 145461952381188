import {inject, Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs/internal/BehaviorSubject";
import {ToastService} from "@common/core/service/toast";
import {SendlinkService} from "@shared/service/api/sendlink";

@Injectable()
export class FooterSubscribeService {
    public isShow: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public phone: BehaviorSubject<string> = new BehaviorSubject("");
    private sendlinkService = inject(SendlinkService);

    constructor(private _toast: ToastService) {}

    send(_phone: string): Promise<void> {
        return new Promise((resolve, reject) => {
            this.sendlinkService.post({phone: _phone, param: "sms_blue"}).subscribe({
                next: () => {
                    this._toast.success("Сообщение отправлено");
                    resolve();
                },
                error: () => {
                    this._toast.error("Произошла ошибка");
                    reject();
                },
            });
        });
    }
}
