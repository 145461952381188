import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input} from "@angular/core";
import {PlatformService} from "@common/core/service/platform";

export interface ImageLineListComponent {
    alt?: string;
    src: string;
}

@Component({
    selector: "ma-ui-image-line",
    templateUrl: "./image-line.component.html",
    styleUrls: ["./image-line.component.less"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageLineComponent {
    private platform = inject(PlatformService);
    private cdr = inject(ChangeDetectorRef);
    private _list: ImageLineListComponent[] = [];
    @Input() onLastArrow: boolean = false;
    @Input() set list(list: ImageLineListComponent[]) {
        if (this.platform.browser) {
            this._list = [];
            setTimeout(() => {
                this._list = list;
                this.initValue();
                this.cdr.detectChanges();
            }, 50);
        } else {
            this._list = list;
            this.initValue();
        }
    }

    @Input() count: number = 0;

    public images: ImageLineListComponent[] = [];
    private readonly COUNT_IMAGE: number = 4;

    initValue() {
        this.images = this._list;
        if (this.images.length < this.COUNT_IMAGE) {
            for (let i = this.images.length; i < this.count; i++) {
                this.images.push({
                    src: "/images/nopic.svg",
                });
            }
        }
        this.images = this._list.slice(0, this.COUNT_IMAGE);
        this.images.map((v) => {
            if (!v.src) {
                v.src = "/images/nopic.svg";
            }
            return v;
        });
    }
}
