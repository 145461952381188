import {inject, Injectable} from "@angular/core";
import {NavigationCancel, NavigationEnd, Router} from "@angular/router";
import {SessionService} from "@common/core/service/session/session.service";
import {filter} from "rxjs/operators";

@Injectable({
    providedIn: "root",
})
export class BackUrlService {
    public url: string = "";
    private LIST_BLACK_EXCEPTION = ["/auth", "/oauth"];
    private readonly NAME_VALUE = "ma_backurl";

    private router = inject(Router);
    private sessionService = inject(SessionService);

    /**
     * Запись url переходов
     */
    public load() {
        this.router.events
            .pipe(
                filter((event) => {
                    return event instanceof NavigationCancel || event instanceof NavigationEnd;
                }),
            )
            .subscribe((event: NavigationCancel | NavigationEnd) => {
                this.url = event.url;
                this.save();
            });

        return;
    }

    /**
     * Редирект обратно, если есть урл
     */
    public async redirect(): Promise<boolean> {
        let url = this.getUrl();
        const urlNow = this.router.url.toString().replace(/\?.*/gi, "");
        if (!url) {
            url = "/";
        }
        if (url === urlNow) {
            return await this.router.navigate([urlNow], {queryParams: {ref: 1}});
        }
        return await this.router.navigateByUrl(url);
    }

    public set(url: string): void {
        this.sessionService.set(this.NAME_VALUE, url);
    }

    /**
     * Возвращает урл
     *
     * @returns {string}
     */
    public getUrl(): string {
        try {
            return this.sessionService.get(this.NAME_VALUE);
        } catch {
            return "";
        }
    }

    /**
     * Проверка урла в списке запрещенных
     *
     * @returns {boolean}
     */
    private check(): boolean {
        let trigger = true;
        this.LIST_BLACK_EXCEPTION.forEach((exception) => {
            if (this.url.indexOf(exception) === 0) {
                trigger = false;
            }
        });
        return trigger;
    }

    /**
     * Сохраняем урл в store
     */
    private save(): void {
        if (this.check()) {
            const url = this.url !== "/" ? this.url : "";
            this.set(url);
        }
    }
}
