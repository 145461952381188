import {Pipe, PipeTransform} from "@angular/core";
import {ConfigService} from "@common/core/service/config";

@Pipe({
    name: "domain",
})
export class DomainPipe implements PipeTransform {
    transform(): string {
        const configSite = new ConfigService().get("site");
        return <string>configSite.domain ? configSite.domain : "";
    }
}
