import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {SearchComponent} from "./search.component";
import {ReactiveFormsModule} from "@angular/forms";
import {AngularSvgIconModule} from "angular-svg-icon";

@NgModule({
    declarations: [SearchComponent],
    imports: [CommonModule, ReactiveFormsModule, AngularSvgIconModule],
    exports: [SearchComponent],
})
export class InputSearchModule {}
