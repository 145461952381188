import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {CitiesLocateInGet, CitiesLocateOutGet} from "./interface";

@Injectable({
    providedIn: "root",
})
export class CitiesLocateService {
    constructor(private _httpClient: HttpClient) {}

    /**
     * Список городов
     *
     * @returns {Observable<CitiesLocateOutGet>}
     */
    get(_obj: CitiesLocateInGet): Observable<CitiesLocateOutGet> {
        return this._httpClient.get<CitiesLocateOutGet>("ma/site/v3/cities/locate", {params: {data: <string>JSON.stringify(_obj)}});
    }
}
