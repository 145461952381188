import {ChangeDetectionStrategy, Component, Input} from "@angular/core";
import {SvgIconComponent} from "angular-svg-icon";

@Component({
    selector: "ma-elem-history",
    standalone: true,
    templateUrl: "./elem-history.component.html",
    styleUrls: ["./elem-history.component.less"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [SvgIconComponent],
})
export class ElemHistoryComponent {
    @Input() elem: string = "";
}
