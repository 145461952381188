import {ChangeDetectionStrategy, Component, inject, OnInit, signal} from "@angular/core";
import {CityService} from "@core/service/city";
import {City} from "@core/model/city";
import {Dialog} from "@angular/cdk/dialog";
import {DIALOG_UI_CITY_SELECTION_CONFIG} from "@shared/module_v2/dialog/ui/city-selection/city-selection";
import {PopupYourCityService} from "@common/shared/service/popup-your-city/popup-your-city.service";
import {PlatformService} from "@common/core/service/platform";

@Component({
    standalone: true,
    imports: [],
    selector: "ma-ui-popup-your-city",
    templateUrl: "./popup-your-city.component.html",
    styleUrls: ["./popup-your-city.component.less"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiPopupYourCityComponent implements OnInit {
    public city: City;
    public loadingDialog = signal(false);
    public isActive = signal(false);

    private readonly NAME_STORAGE: string = "ma_session_change_city";
    private dialog = inject(Dialog);
    private popupYourCityService = inject(PopupYourCityService);
    private platform = inject(PlatformService);
    private _city = inject(CityService);

    ngOnInit() {
        this.city = this._city.city$.getValue();
        if (this.platform.browser) {
            if (!this.popupYourCityService.getStatus()) {
                this.isActive.set(true);
                this.popupYourCityService.setStatus();
            }
        }
    }

    onClickYes() {
        this.isActive.set(false);
    }

    onClickNo(event: Event) {
        event.stopPropagation();
        if (this.loadingDialog() === false) {
            this.loadingDialog.set(true);

            import("@shared/module_v2/dialog/ui/city-selection/city-selection.component")
                .then((res) => {
                    this.dialog.open(res.DialogCitySelectionComponent, DIALOG_UI_CITY_SELECTION_CONFIG);
                })
                .finally(() => {
                    setTimeout(() => {
                        this.loadingDialog.set(false);
                        this.isActive.set(false);
                    }, 400);
                });
        }
    }
}
