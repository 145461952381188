import {Injectable} from "@angular/core";
import {ToastrService} from "ngx-toastr";
import {LazyStyleService} from "@core/service/lazy-style";
import {IndividualConfig} from "ngx-toastr/toastr/toastr-config";

@Injectable({
    providedIn: "root",
})
export class ToastService {
    private options: Partial<IndividualConfig> = {
        closeButton: true,
        enableHtml: true,
        timeOut: 10000,
    };

    constructor(
        private _toaster: ToastrService,
        private _lazyStyleService: LazyStyleService,
    ) {
        this._lazyStyleService.load("toastr");
    }

    /**
     * Удачный ответ
     *
     * @param {string} text
     * @param {Partial<IndividualConfig>} options
     */
    success(text: string, options: Partial<IndividualConfig> = {}): void {
        this._toaster.success(text, "", {...this.options, timeOut: 10000, ...options});
    }

    /**
     * Ошибка
     *
     * @param {string} text
     * @param {Partial<IndividualConfig>} options
     */
    error(text: string = "Произошла ошибка", options: Partial<IndividualConfig> = {}): void {
        this._toaster.error(text, "", {...this.options, ...options});
    }

    /**
     * Предупреждение
     *
     * @param {string} text
     * @param {Partial<IndividualConfig>} options
     */
    warning(text: string, options: Partial<IndividualConfig> = {}): void {
        this._toaster.warning(text, "", {...this.options, ...options});
    }

    /**
     * Информация
     *
     * @param {string} text
     * @param {Partial<IndividualConfig>} options
     */
    info(text: string, options: Partial<IndividualConfig> = {}): void {
        this._toaster.info(text, "", {...this.options, ...options});
    }
}
