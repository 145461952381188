<nav>
    <div class="title"><b>Помощь</b></div>
    <a title="Как заказать" routerLink="/help">Как заказать</a>
    <a title="Оплата" routerLink="/help/payment">Оплата</a>
    <a title="Популярные вопросы" routerLink="/help/faq">Популярные вопросы</a>
    <a title="Карта сайта" routerLink="/sitemap-html">Карта сайта</a>
    <a title="Поиск по бренду" routerLink="/products/alphabet/а">Поиск по бренду</a>
    <a title="Индекс наличия" routerLink="/ai-items">Индекс наличия</a>
    <a title="Поиск аптек на карте" routerLink="/geo">Поиск аптек на карте</a>
    <a title="Обмен и Возврат" routerLink="/return">Обмен и Возврат</a>
</nav>
