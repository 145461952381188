import {Inject, Injectable, RendererFactory2, ViewEncapsulation} from "@angular/core";
import {Meta, Title} from "@angular/platform-browser";
import {ActivatedRoute, NavigationEnd, Router, UrlSerializer} from "@angular/router";
import {ConfigService} from "@common/core/service/config";
import {BehaviorSubject} from "rxjs/internal/BehaviorSubject";
import {filter, map, mergeMap} from "rxjs/operators";
import {DOCUMENT} from "@angular/common";
import {CityService} from "@core/service/city";
import {Url} from "@core/service/url";

@Injectable({
    providedIn: "root",
})
export class MetaTagService {
    private titleDefault: string = "";
    public title$: BehaviorSubject<{title: string; url: string}> = new BehaviorSubject({title: "", url: ""});

    constructor(
        private titleService: Title,
        private _meta: Meta,
        private _city: CityService,
        private rendererFactory: RendererFactory2,
        private _urlSerializer: UrlSerializer,
        @Inject(DOCUMENT) private document,
        private config: ConfigService,
        private _url: Url,
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
    ) {}

    public run() {
        this.titleDefault = <string>this.config.get("site")["title"];
        this.titleService.setTitle(this.titleDefault);
        this.setOgDefault();
        this._router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                map(() => this._activatedRoute),
                map((route) => {
                    while (route.firstChild) {
                        route = route.firstChild;
                    }
                    return route;
                }),
                filter((route) => route.outlet === "primary"),
                mergeMap((route) => route.data),
            )
            .subscribe((data) => {
                if (data.title) {
                    this.setTitle(data.title);
                }
            });
    }

    /**
     * Установка title
     *
     * @param title названия
     */
    public setTitle(title: string) {
        if (title) {
            if (title.length > 0) {
                let titleNew: string = this.titleDefault;
                const urlOld = this.title$.getValue();
                const pathname = this._url.pathname();
                const search = this._url.search();
                const urlNew = decodeURI(pathname + search);
                if (title) {
                    if (titleNew.length > 0) {
                        titleNew = title + " " + titleNew;
                    } else {
                        titleNew = title;
                    }
                }

                const queryParams = this._activatedRoute.snapshot.queryParams;
                if (queryParams["page"] > 1) {
                    titleNew += " | Страница " + queryParams["page"];
                }
                this.titleService.setTitle(titleNew);
                if (urlOld.url !== urlNew) {
                    this.title$.next({title: titleNew, url: pathname});
                }
            }
        }
    }

    /**
     * Получение title
     *
     */
    public getTitle(): string {
        return this.titleService.getTitle();
    }

    /**
     * Установка description
     *
     * @param text названия
     */
    public setDescription(text: string) {
        if (text.length > 0) {
            this.upsertTag("description", text);
        }
    }

    /**
     * Получение description
     *
     * @param text названия
     */
    public getDescription(): string {
        return this.getTag("name=description");
    }

    /**
     * Удаление description
     *
     */
    public deleteDescription() {
        this._meta.removeTag('name="description"');
    }

    /**
     * Установка Og
     *
     * @param {{}} _obj
     */
    public setOg(_obj = {}): void {
        if (_obj["url"]) {
            this.upsertOgTag("og:url", _obj["url"]);
        }

        if (_obj["site_name"]) {
            this.upsertOgTag("og:site_name", _obj["site_name"]);
        }

        if (_obj["title"]) {
            this.upsertOgTag("og:title", _obj["title"]);
        }

        if (_obj["description"]) {
            this.upsertOgTag("og:description", _obj["description"]);
        }

        if (_obj["image"]) {
            this.upsertOgTag("og:image", _obj["image"]);
        }

        if (_obj["image_alt"]) {
            this.upsertOgTag("og:image:alt", _obj["image_alt"]);
        }

        if (_obj["image_width"]) {
            this.upsertOgTag("og:image:width", _obj["image_width"]);
        }

        if (_obj["image_height"]) {
            this.upsertOgTag("og:image:height", _obj["image_height"]);
        }

        if (_obj["locale"]) {
            this.upsertOgTag("og:locale", _obj["locale"]);
        }

        if (_obj["type"]) {
            this.upsertOgTag("og:type", _obj["type"]);
        }
    }

    /**
     * Установка Og по умочанию
     *
     */
    public setOgDefault(): void {
        const url: string = this.config.get("site").host;
        this.upsertOgTag("og:url", url);

        const site_name: string = this.config.get("site").domain;
        this.upsertOgTag("og:site_name", site_name);

        const title: string = "Агрегатор аптек Мегаптека.ру";
        this.upsertOgTag("og:title", title);

        const description: string =
            "Мегаптека - сервис для поиска, сравнения цен и заказа лекарственных средств и других аптечных товаров по лучшей цене в любом городе.";
        this.upsertOgTag("og:description", description);

        const image: string = `${url}/images/og/og__megapteka_sharing.jpg`;
        this.upsertOgTag("og:image", image);

        const locale: string = "ru_RU";
        this.upsertOgTag("og:locale", locale);

        const type: string = "website";
        this.upsertOgTag("og:type", type);
    }

    /**
     * Апсер для og
     *
     * @param {string} name
     * @param {string} content
     */
    private upsertOgTag(name: string, content: string) {
        if (!this._meta.getTag('property="' + name + '"')) {
            this._meta.addTag({property: name, content: content});
        } else {
            this._meta.updateTag({property: name, content: content});
        }
    }

    /**
     * Установка тега
     *
     * @param name
     * @param content
     */
    public upsertTag(name: string, content: string) {
        this._meta.updateTag({
            name: name,
            content: content,
        });
    }

    /**
     * Получение тега
     *
     * @param name string
     */
    public getTag(name: string): string {
        return this._meta.getTag(name).content ? this._meta.getTag(name).content : "";
    }

    public setRobots(str: string) {
        const city = this._city.data;
        let content = str;

        if (city.is_index === false) {
            content = "noindex, follow";
        }

        const urlArrException = [
            "specials",
            "analytics",
            "help",
            "about",
            "pharmacies",
            "check",
            "products",
            "terms",
            "feedback_policy",
            "agreements",
        ];
        const pathname = this._url.pathname();

        const urlTest = urlArrException.filter((r) => {
            const reg = new RegExp(`^\/${r}.*`, "i");
            return reg.test(pathname);
        });

        if (
            pathname === "" ||
            pathname === "/" ||
            pathname === `/${city.code}` ||
            urlTest.length > 0 ||
            pathname.indexOf("/sitemap-html") !== -1 ||
            (pathname.indexOf("/product/") !== -1 && pathname.indexOf("/reviews") !== -1) ||
            (pathname.indexOf("/product/") !== -1 && pathname.indexOf("/analogues") !== -1)
        ) {
            content = "index, follow";
        }

        // Исключение для продуктов
        if (this._activatedRoute.root.children[0]?.children[0]?.snapshot) {
            const paramsProduct = this._activatedRoute.root.children[0].children[0].snapshot.params;
            if (paramsProduct["product_code"] && pathname.indexOf("/reviews") === -1 && pathname.indexOf("/analogues") === -1) {
                content = str;
            }
        }

        const meta = this._meta.getTag('name="robots"');
        if (meta) {
            this._meta.updateTag({name: "robots", content: content});
        } else {
            this._meta.addTag({name: "robots", content: content});
        }
    }

    public setCanonical(url) {
        try {
            this.setLink(url.replace(/page=[0-9]+/gi, ""), "canonical");
        } catch (e) {
            console.error(e);
        }
    }

    public setLink(url: string, rel: string = "") {
        try {
            if (url && rel) {
                url = url.replace(/(https:\/\/megapteka\.ru)/gi, ""); // позже переделать нормально
                const urlSerializer = this._urlSerializer.parse(url);
                const queryParams = urlSerializer.queryParams;
                if (queryParams["page"]) {
                    urlSerializer.queryParams = {
                        page: queryParams["page"],
                    };
                } else if (queryParams["q"]) {
                    urlSerializer.queryParams = {
                        q: queryParams["q"],
                    };
                } else {
                    urlSerializer.queryParams = {};
                }

                const renderer = this.rendererFactory.createRenderer(this.document, {
                    id: "-1",
                    encapsulation: ViewEncapsulation.None,
                    styles: [],
                    data: {},
                });

                const link = renderer.createElement("link");
                const head = this.document.head;

                if (head !== null) {
                    this.setDelete(rel);
                    if (rel) {
                        renderer.setAttribute(link, "rel", rel);
                    }

                    renderer.setAttribute(link, "href", "https://megapteka.ru" + urlSerializer.toString());
                    renderer.appendChild(head, link);
                }
            }
        } catch (e) {
            console.error(e);
        }
    }

    public setDelete(rel: string) {
        try {
            const renderer = this.rendererFactory.createRenderer(this.document, {
                id: "-1",
                encapsulation: ViewEncapsulation.None,
                styles: [],
                data: {},
            });
            const head = this.document.head;
            const selector = this.document.querySelectorAll(`link[rel="${rel}"]`);
            for (const link of selector) {
                renderer.removeChild(head, link);
            }
        } catch (e) {
            console.error(e);
        }
    }

    public setScriptText(str: string, id: string) {
        if (!id) {
            return;
        }
        const findId = this.document.getElementById(id);
        if (findId) {
            findId.remove();
        }
        if (str.length > 0) {
            const script = this.document.createElement("script");
            script["type"] = "application/ld+json";
            script["id"] = id;
            script["text"] = str;

            this.document.head.appendChild(script);
        }
    }
}
