import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {HeaderFloatingComponent} from "@shared/module_v2/layout/header-floating/header-floating.component";
import {SvgLogoMaModule} from "@shared/module_v2/svg/logo-ma/logo-ma.module";
import {RouterModule} from "@angular/router";
import {UiBasketSmallModule} from "@shared/module_v2/ui/basket-small/basket-small.module";
import {SvgLogoMModule} from "@shared/module_v2/svg/logo-m/logo-m.module";
import {LayoutMenuCatalogModule} from "@shared/module_v2/layout/menu-catalog/menu-catalog.module";
import {SearchHeaderComponent} from "@component/ui/search-header/search-header.component";

@NgModule({
    declarations: [HeaderFloatingComponent],
    imports: [
        CommonModule,
        SvgLogoMaModule,
        RouterModule,
        UiBasketSmallModule,
        SvgLogoMModule,
        LayoutMenuCatalogModule,
        SearchHeaderComponent,
    ],
    exports: [HeaderFloatingComponent],
})
export class LayoutHeaderFloatingModule {}
