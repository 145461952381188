import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from "@angular/core";
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {FooterSubscribeService} from "./sms-installation.service";
import {ImageLazyModule} from "@shared/module/html/image-lazy/image-lazy.module";
import {PhoneComponent} from "@shared/module/input/phone/phone.component";

@Component({
    standalone: true,
    selector: "ma-ui-sms-installation",
    templateUrl: "./sms-installation.component.html",
    styleUrls: ["sms-installation.component.less"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [ImageLazyModule, PhoneComponent],
    providers: [FooterSubscribeService],
})
export class SmsInstallationComponent implements OnInit {
    public mask = ["+", "7", " ", "(", /\d/, /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, /\d/];
    public phone: string | null;
    public error = false;
    public loader = false;
    public form = new UntypedFormGroup({
        phone: new UntypedFormControl(),
    });

    constructor(
        private _fb: UntypedFormBuilder,
        private _cdr: ChangeDetectorRef,
        private _service: FooterSubscribeService,
    ) {}

    ngOnInit() {
        this.form = this._fb.group({
            phone: new UntypedFormControl(""),
            submit: new UntypedFormControl(false),
        });

        this._service.phone.subscribe((res) => {
            if (res.length > 0) {
                this.form.get("phone").setValue("+" + res);
                this._cdr.detectChanges();
            }
        });
    }

    onClick() {
        this.form.get("submit").setValue(true);

        if (this.form.valid) {
            this.loader = true;
            this._service
                .send(this.form.get("phone").value)
                .then(() => {
                    this.form.get("phone").setValue("");
                    this.loader = false;
                    this.form.reset();
                    this._cdr.detectChanges();
                })
                .catch(() => {
                    this.loader = false;
                    this._cdr.detectChanges();
                });
        }
    }
}
