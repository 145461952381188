import {ChangeDetectionStrategy, Component, EventEmitter, inject, OnInit, Output} from "@angular/core";
import {SearchHeaderHintsHistoryService} from "@shared/module_v2/ui-request/search-header-hints/search-header-hints-history.service";
import {SearchHeaderHintsService} from "@shared/module_v2/ui-request/search-header-hints/search-header-hints.service";
import {SearchSuggestionsOutGet} from "@shared/service/api/search/suggestions";
import {GoogleAnalyticsService} from "@core/module/google/analytics";
import {YandexMetrikaService} from "@core/service/yandex-metrika";
import {SearchQueriesOutGet} from "@shared/service/api/search/queries";
import {CityService} from "@core/service/city";
import {RouterModule} from "@angular/router";
import {UiStickerColorComponent} from "@component/ui/sticker-color/sticker-color.component";
import {ColorRandomPipe} from "@component/ui-request/search-header-hints/color-random.pipe";
import {NgTemplateOutlet, SlicePipe} from "@angular/common";
import {ElemHistoryComponent} from "@component/ui-request/search-header-hints/elem-history/elem-history.component";
import {ElemItemComponent} from "@component/ui-request/search-header-hints/elem-item/elem-item.component";
import {ElemProductComponent} from "@component/ui-request/search-header-hints/elem-product/elem-product.component";

@Component({
    selector: "ma-ui-request-search-header-hints",
    standalone: true,
    templateUrl: "./search-header-hints.component.html",
    styleUrls: ["./search-header-hints.component.less"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [SearchHeaderHintsHistoryService],
    imports: [
        RouterModule,
        UiStickerColorComponent,
        ColorRandomPipe,
        SlicePipe,
        ElemHistoryComponent,
        ElemItemComponent,
        ElemProductComponent,
        NgTemplateOutlet,
    ],
})
export class SearchHeaderHintsComponent implements OnInit {
    public cityCode: string = "";

    private searchHeaderHintsService = inject(SearchHeaderHintsService);
    private searchHeaderHintsHistoryService = inject(SearchHeaderHintsHistoryService);

    @Output() onClearHistory: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() onSearchValue: EventEmitter<string> = new EventEmitter<string>();

    public data = this.searchHeaderHintsService.data.asReadonly();

    constructor(
        private _googleAnalytics: GoogleAnalyticsService,
        private _yandexMetrikaService: YandexMetrikaService,
        private _city: CityService,
    ) {}

    ngOnInit(): void {
        this.cityCode = this._city.data.code;
    }

    clearHistory() {
        this.searchHeaderHintsService.clear();
    }

    onClickProduct(elem: SearchSuggestionsOutGet["products"][0]) {
        this._googleAnalytics.eventClickVirtualSuggestClick();
        this._yandexMetrikaService.reachGoal("suggest_click");
        this._yandexMetrikaService.reachGoal("40248_suggest_click");
        // #51966 > Выбор цветного саджеста/ пункта из истории поиска (Поиск_товаров)
        this._yandexMetrikaService.reachGoal("GL_action_search", {
            type: "Подсказка по введенной строке",
            alt: "product",
            text: elem.name,
        });
        this.setHistory(elem.name);
        this.onSearchValue.emit(elem.name);
    }

    onClickTag(elem: SearchSuggestionsOutGet["tags"][0]) {
        // #51966 > Выбор цветного саджеста/ пункта из истории поиска (Поиск_товаров)
        this._yandexMetrikaService.reachGoal("GL_action_search", {
            type: "Подсказка по введенной строке",
            alt: "tag",
            text: elem.name,
        });
        this.setHistory(elem.name);
        this.onSearchValue.emit(elem.name);
    }

    onClickGroup(elem: SearchSuggestionsOutGet["groups"][0]) {
        // #51966 > Выбор цветного саджеста/ пункта из истории поиска (Поиск_товаров)
        this._yandexMetrikaService.reachGoal("GL_action_search", {
            type: "Подсказка по введенной строке",
            alt: "group",
            text: elem.name,
        });
        this.setHistory(elem.name);
        this.onSearchValue.emit(elem.name);
    }

    onClickHistory(elem: string) {
        // #51966 > Выбор цветного саджеста/ пункта из истории поиска (Поиск_товаров)
        this._yandexMetrikaService.reachGoal("GL_action_search", {
            type: "История поиска",
            text: elem,
        });
        this.onSearchValue.emit(elem);
    }

    onClickTagColor(elem: SearchQueriesOutGet["list"][0]) {
        // #51966 > Выбор цветного саджеста/ пункта из истории поиска (Поиск_товаров)
        this._yandexMetrikaService.reachGoal("GL_action_search", {
            type: "Цветной саджест",
            text: elem.query,
        });
        this.onSearchValue.emit(elem.query);
    }

    setHistory(query: string) {
        this.searchHeaderHintsHistoryService.add(query);
    }
}
