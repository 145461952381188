import {Injectable, Injector, makeStateKey, StateKey, TransferState} from "@angular/core";
import {HttpHandler, HttpRequest, HttpResponse} from "@angular/common/http";

import {PlatformService} from "@common/core/service/platform";
import {of, tap} from "rxjs";

@Injectable()
export class HttpCachingInterceptor {
    private _transferState: TransferState;
    private _platform: PlatformService;
    private keys = new Map<string, StateKey<string>>();

    constructor(private _injector: Injector) {
        this._transferState = _injector.get(TransferState);
        this._platform = _injector.get(PlatformService);
    }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        // Если запрос к статическим файлам или внешним ресурсам, то пропускаем
        if (/^(\.*\/*assets|https:|http:)/i.test(req.url)) {
            return next.handle(req);
        }
        if (req.method !== "GET") {
            return next.handle(req);
        }
        // Для старой библиотеки апи
        if (req.headers.get("token") !== null) {
            return next.handle(req);
        }

        const json = <string>JSON.stringify(req.params.get("data"));
        const cacheKey: any = this.getStateKey(req.url + json);

        if (this.hasCache(cacheKey)) {
            return of(new HttpResponse<any>({body: this.queryCache(cacheKey)})).pipe(
                tap(() => {
                    if (this._platform.browser) {
                        this.removeCache(cacheKey);
                    }
                }),
            );
        } else {
            if (this._platform.server) {
                return next.handle(req).pipe(
                    tap((stateEvent) => {
                        if (stateEvent instanceof HttpResponse) {
                            // нужно чтобы когда нет города получать из State в браузере полученый уже запрос от server
                            if (req.url === "ma/site/v2/cities/locate") {
                                const result = stateEvent.body;
                                if (!!Object.keys(req.params.get("data")).length && result["city"]["id"]) {
                                    const cacheKeyId = this.getStateKey(
                                        req.url + JSON.stringify(JSON.stringify({id: result["city"]["id"]})),
                                    );
                                    const cacheKeyCode = this.getStateKey(
                                        req.url + JSON.stringify(JSON.stringify({code: result["city"]["code"]})),
                                    );
                                    this.setCache(cacheKeyId, stateEvent.body);
                                    this.setCache(cacheKeyCode, stateEvent.body);
                                }
                            } else {
                                this.setCache(cacheKey, stateEvent.body);
                            }
                        }
                    }),
                );
            }
        }
        return next.handle(req);
    }

    private getStateKey(key: string): StateKey<string> {
        if (this.keys.has(key)) {
            return this.keys.get(key);
        }
        this.keys.set(key, makeStateKey(key));
        return this.keys.get(key);
    }

    /**
     * Проверка кэш
     *
     * @param key
     * @private
     */
    private hasCache(key: StateKey<string>): boolean {
        return this._transferState.hasKey(key);
    }

    /**
     * Возращает кэш
     *
     * @param key
     * @private
     */
    private queryCache(key: StateKey<string>) {
        return this._transferState.get(key, null);
    }

    /**
     * Установка кэш
     *
     * @param key
     * @param data
     *
     * @private
     */
    private setCache(key: StateKey<string>, data: any): void {
        this._transferState.set(key, data);
    }

    /**
     * Удаление кэш
     *
     * @param key
     * @private
     */
    private removeCache(key: StateKey<string>): void {
        this._transferState.remove(key);
    }
}
