import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {NumEndingPipe} from "./pipe/num-ending";
import {PhoneCallPipe} from "./pipe/phone-call";
import {DateSitePipe} from "./pipe/date-site";
import {DomainPipe} from "./pipe/domain";
import {ReplacePipe} from "./pipe/replace";
import {FormatHtmlPipe} from "./pipe/format-html";
import {FormatSearchBoldPipe} from "./pipe/format-search-bold";
import {TimeSecondsPipe} from "@shared/pipe/time-seconds";
import {SafeUrlPipe} from "@shared/pipe/safe-url";

const arr = [
    NumEndingPipe,
    PhoneCallPipe,
    DateSitePipe,
    DomainPipe,
    ReplacePipe,
    FormatHtmlPipe,
    FormatSearchBoldPipe,
    TimeSecondsPipe,
    SafeUrlPipe,
];

@NgModule({
    imports: [CommonModule],
    declarations: arr,
    exports: arr,
})
export class SharedPipeModule {}
