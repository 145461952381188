import {ChangeDetectionStrategy, Component, HostListener, inject, Input, OnInit, signal} from "@angular/core";
import {PlatformService} from "@common/core/service/platform";
import {GroupsMenuOutGet, GroupsMenuService} from "@shared/service/api/groups/menu";
import {MenuCatalogService} from "./menu-catalog.service";

@Component({
    selector: "ma-layout-menu-catalog",
    templateUrl: "./menu-catalog.component.html",
    styleUrls: ["./menu-catalog.component.less"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [GroupsMenuService, MenuCatalogService],
})
export class MenuCatalogComponent implements OnInit {
    @Input() name: string = "Каталог";

    @HostListener("mouseenter") mouseenter() {
        this.showList = true;
    }

    @HostListener("mouseleave") mouseleave() {
        this.showList = false;
    }

    private platform = inject(PlatformService);
    private menuCatalogService = inject(MenuCatalogService);
    public showList: boolean = false;
    public list = signal<GroupsMenuOutGet["list"]>([]);

    ngOnInit() {
        if (this.platform.browser) {
            this.menuCatalogService.getGroups().subscribe((list) => {
                this.list.set(list);
            });
        }
    }
}
