import {inject, Injectable} from "@angular/core";
import {ApiParametersService} from "@common/shared/service/api/parameters";

interface ParametersData {
    ORDER_MIN_SUM?: number;
    DELAY_SEARCH_SUGGESTS?: number;
    SITE_SHOW_LICENSE?: boolean;
    SITE_LICENSE_HTML?: string;
    SEARCH_RESULT_CAUTION?: number;
}

@Injectable({
    providedIn: "root",
})
export class ParametersService {
    public parametersData: ParametersData = {
        ORDER_MIN_SUM: 0,
        SITE_SHOW_LICENSE: false,
        SITE_LICENSE_HTML: "",
        DELAY_SEARCH_SUGGESTS: 0.5,
    };
    string = [];
    private apiParametersService = inject(ApiParametersService);

    load() {
        return this.apiParametersService.get().subscribe((res) => {
            res.list.forEach((v) => {
                if (v.key === "ORDER_MIN_SUM") {
                    this.parametersData[v.key] = +v.value;
                }
                if (v.key === "DELAY_SEARCH_SUGGESTS") {
                    this.parametersData[v.key] = +v.value * 1000;
                }
                if (v.key === "SITE_SHOW_LICENSE") {
                    this.parametersData[v.key] = v.value === "true";
                }
                if (v.key === "SITE_LICENSE_HTML") {
                    this.parametersData[v.key] = v.value;
                }
                if (v.key === "SEARCH_RESULT_CAUTION") {
                    this.parametersData[v.key] = +v.value;
                }
            });

            return true;
        });
    }
}
