import {Injectable} from "@angular/core";
import {PlatformService} from "@common/core/service/platform";

@Injectable({
    providedIn: "root",
})
export class SessionService {
    constructor(private _platform: PlatformService) {}

    /**
     * Запись параметра
     *
     * @param {string} name
     * @param {string} value
     */
    public set(name: string, value: string) {
        if (this._platform.browser) {
            if (value) {
                sessionStorage.setItem(name, value);
            } else {
                this.remove(name);
            }
        }
    }

    /**
     * Возращает параметр
     *
     * @param {string} name
     * @returns {any}
     */
    public get(name: string) {
        if (this._platform.browser) {
            return sessionStorage.getItem(name);
        }
        return "";
    }

    /**
     * Удаляет параметр
     *
     * @param {string} name
     */
    public remove(name: string) {
        if (this._platform.browser) {
            sessionStorage.removeItem(name);
        }
    }
}
