import {inject, Injectable} from "@angular/core";
import {Observable, Subscription} from "rxjs";
import {AuthTelemetryInPut, AuthTelemetryOutPut} from "./interface";
import {DeviceTypeService} from "@core/service/device-type/device-type.service";
import {CityService} from "@core/service/city";
import {HttpClient} from "@angular/common/http";

@Injectable({providedIn: "root"})
export class ApiAuthTelemetryService {
    private httpClient = inject(HttpClient);
    private deviceTypeService = inject(DeviceTypeService);
    private cityService = inject(CityService);

    /**
     *
     * @returns {Observable<AuthTelemetryOutPut>}
     */
    put(_obj: AuthTelemetryInPut): Subscription {
        _obj["device_type_id"] = this.deviceTypeService.get();
        _obj["city_id"] = this.cityService.data.id;
        return this.httpClient.put<AuthTelemetryOutPut>("ma/site/v1/auth/telemetry", _obj).subscribe();
    }
}
