import {Inject, Injectable, Injector} from "@angular/core";
import {Router} from "@angular/router";
import {HttpStatus} from "@common/core/service/http-status";
import {Redirect} from "@core/service/redirect/interface";

@Injectable({
    providedIn: "root",
})
export class RedirectBrowserService implements Redirect {
    constructor(
        @Inject(Injector) public _injector: Injector,
        @Inject(HttpStatus) public _httpStatus: HttpStatus,
    ) {}

    _200(url: string, query?: any) {
        const route = this._injector.get(Router);
        route.navigate([url], {queryParams: query});
    }

    async _301(url: string, query?: any) {
        const route = this._injector.get(Router);
        await route.navigate([url], {queryParams: query});
    }

    async _404() {
        const route = this._injector.get(Router);
        await route.navigateByUrl("/404", {skipLocationChange: true});
    }

    main() {
        const route = this._injector.get(Router);
        route.navigateByUrl("/");
    }
}
