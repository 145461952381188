import {inject, Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {ApiParametersOutGet} from "./interface";

@Injectable({providedIn: "root"})
export class ApiParametersService {
    private httpClient = inject(HttpClient);

    /**
     *
     * @returns {Observable<ApiParametersOutGet>}
     */
    get(): Observable<ApiParametersOutGet> {
        return this.httpClient.get<ApiParametersOutGet>("ma/site/v1/parameters");
    }
}
