import {Injectable} from "@angular/core";
import {ItemsFavoritesInGet, ItemsFavoritesInPut, ItemsFavoritesOutGet, ItemsFavoritesOutGetOld, ItemsFavoritesOutPut} from "./interface";
import {HttpClient} from "@angular/common/http";
import {CityService} from "@core/service/city";
import {Observable} from "rxjs";

@Injectable({
    providedIn: "root",
})
export class ItemsFavoritesService {
    constructor(
        private _httpClient: HttpClient,
        private _city: CityService,
    ) {}

    /**
     * @param {ItemsFavoritesInGet} _obj
     * @returns {Observable<ItemsFavoritesOutGet>}
     */
    get(_obj?: ItemsFavoritesInGet): Observable<ItemsFavoritesOutGet> {
        _obj["city_id"] = this._city.data.id;
        return this._httpClient.get<ItemsFavoritesOutGet>("ma/site/v5/items/favorites", {params: {data: <string>JSON.stringify(_obj)}});
    }

    /**
     * @param {ItemsFavoritesInGet} _obj
     * @returns {Observable<ItemsFavoritesOutGetOld>}
     */
    getOld(_obj?: ItemsFavoritesInGet): Observable<ItemsFavoritesOutGetOld> {
        _obj["city_id"] = this._city.data.id;
        return this._httpClient.get<ItemsFavoritesOutGetOld>("ma/site/v3/items/favorites", {params: {data: <string>JSON.stringify(_obj)}});
    }

    /**
     * @param {ItemsFavoritesInPut} _obj
     * @returns {Observable<ItemsFavoritesOutPut>}
     */
    put(_obj?: ItemsFavoritesInPut): Observable<ItemsFavoritesOutPut> {
        return this._httpClient.put<ItemsFavoritesOutPut>("ma/v3/items/favorites", _obj);
    }
}
