<div class="search-header__container">
    @if (!isFloating) {
        <svg-icon class="search-header__icon search-header__unicorn" [src]="'icons/svg/search_unicorn.svg'"></svg-icon>
    } @else {
        <svg-icon class="search-header__icon search-header__search" [src]="'icons/svg/search.svg'"></svg-icon>
    }
    <input
        class="search-header__input shadow-card"
        #input
        type="text"
        [formControl]="queryControl"
        (keydown.enter)="onClickQuery()"
        (focus)="onInputFocus()"
        (blur)="onInputBlur()"
        placeholder="Название лекарства или действующего вещества" />
    @if (queryControl.value) {
        <svg-icon (click)="onClickDelete()" class="search-header__close" [src]="'icons/svg/close.svg'"></svg-icon>
    }
    @if (isViewHints() && this.hintsStatus() !== "not_fount") {
        <ma-ui-request-search-header-hints
            (onSearchValue)="onSearchValue($event)"
            class="search-header__hints shadow-card"></ma-ui-request-search-header-hints>
    }
</div>
@if (!isFloating) {
    <button type="button" (click)="onClickQuery()" maButtonGeneral class="search-header__button lg uppercase">НАЙТИ</button>
}
