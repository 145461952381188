import {Injectable} from "@angular/core";
import {PlatformService} from "@common/core/service/platform";

@Injectable({
    providedIn: "root",
})
export class MobileService {
    private readonly applePhone = /iPhone/i;
    private readonly appleIpod = /iPod/i;
    private readonly appleWebOs = /webOS/i;
    private readonly appleTablet = /iPad/i;
    private readonly androidPhone = /(?=.*\bAndroid\b)(?=.*\bMobile\b)/i;
    private readonly androidTablet = /Android/i;
    private readonly amazonPhone = /(?=.*\bAndroid\b)(?=.*\bSD4930UR\b)/i;
    private readonly amazonTablet =
        /(?=.*\bAndroid\b)(?=.*\b(?:KFOT|KFTT|KFJWI|KFJWA|KFSOWI|KFTHWI|KFTHWA|KFAPWI|KFAPWA|KFARWI|KFASWI|KFSAWI|KFSAWA)\b)/i;
    private readonly windowsPhone = /Windows Phone/i;
    private readonly windowsIEMobile = /IEMobile/i;
    private readonly windowsTablet = /(?=.*\bWindows\b)(?=.*\bARM\b)/i;
    private readonly otherBlackberry = /BlackBerry/i;
    private readonly otherBlackberry_10 = /BB10/i;
    private readonly otherOpera = /Opera Mini/i;
    private readonly otherChrome = /(CriOS|Chrome)(?=.*\bMobile\b)/i;
    private readonly otherFirefox = /(?=.*\bFirefox\b)(?=.*\bMobile\b)/i;
    private readonly sevenInch = new RegExp("(?:Nexus 7|BNTV250|Kindle Fire|Silk|GT-P1000)", "i");

    public apple: {phone: boolean; ipod: boolean; tablet: boolean; device: boolean; webOs: boolean};
    public amazon: {phone: boolean; tablet: boolean; device: boolean};
    public android: {phone: boolean; tablet: boolean; device: boolean};
    public windows: {phone: boolean; tablet: boolean; device: boolean};
    public other: {blackberry: boolean; blackberry10: boolean; opera: boolean; firefox: boolean; chrome: boolean; device: boolean};
    public seven_inch: boolean;
    public any: boolean;
    public phone: boolean;
    public tablet: boolean;
    public isApple: boolean = false;

    constructor(private _platform: PlatformService) {
        this.isMobileClass();
    }

    /**
     * Является ли устройство мобильным (телефон, планшет и т.д.)
     *
     * @returns {boolean}
     */
    public isMobile(): boolean {
        return this.phone || this.tablet || this.any;
    }

    /**
     * Является ли устройство мобильным (телефон, планшет и т.д.)
     *
     * @returns {boolean}
     */
    public isTablet(): boolean {
        return this.tablet;
    }

    /**
     * Является ли устройство десктопом
     *
     * @returns {boolean}
     */
    public isBrowser(): boolean {
        return !this.phone && !this.tablet && !this.any;
    }

    private test(regex, userAgent): boolean {
        return regex.test(userAgent);
    }

    private isMobileClass(userAgent?: string) {
        let ua: string = "";
        if (this._platform.browser) {
            if (userAgent || navigator.userAgent) {
                ua = userAgent || navigator.userAgent;
            }
        } else {
            if (userAgent || navigator) {
                ua = userAgent || navigator.toString();
            }
        }

        const platform = navigator?.userAgent ? ["platform"] || navigator?.platform : "unknown";

        this.apple = {
            phone: this.test(this.applePhone, ua),
            ipod: this.test(this.appleIpod, ua),
            tablet:
                (!this.test(this.applePhone, ua) && this.test(this.appleTablet, ua)) ||
                (platform === "MacIntel" && navigator?.maxTouchPoints > 1) ||
                navigator?.maxTouchPoints > 3,
            device: this.test(this.applePhone, ua) || this.test(this.appleIpod, ua) || this.test(this.appleTablet, ua),
            webOs: this.test(this.appleWebOs, ua),
        };

        this.amazon = {
            phone: this.test(this.amazonPhone, ua),
            tablet: !this.test(this.amazonPhone, ua) && this.test(this.amazonTablet, ua),
            device: this.test(this.amazonPhone, ua) || this.test(this.amazonTablet, ua),
        };

        this.android = {
            phone: this.test(this.amazonPhone, ua) || this.test(this.androidPhone, ua),
            tablet:
                !this.test(this.amazonPhone, ua) &&
                !this.test(this.androidPhone, ua) &&
                (this.test(this.amazonTablet, ua) || this.test(this.amazonTablet, ua)),
            device:
                this.test(this.amazonPhone, ua) ||
                this.test(this.amazonTablet, ua) ||
                this.test(this.androidPhone, ua) ||
                this.test(this.amazonTablet, ua),
        };

        this.windows = {
            phone: this.test(this.windowsPhone, ua),
            tablet: this.test(this.windowsTablet, ua),
            device: this.test(this.windowsPhone, ua) || this.test(this.windowsTablet, ua),
        };

        this.other = {
            blackberry: this.test(this.otherBlackberry, ua),
            blackberry10: this.test(this.otherBlackberry_10, ua),
            opera: this.test(this.otherOpera, ua),
            firefox: this.test(this.otherFirefox, ua),
            chrome: this.test(this.otherChrome, ua),
            device:
                this.test(this.otherBlackberry, ua) ||
                this.test(this.otherBlackberry_10, ua) ||
                this.test(this.otherOpera, ua) ||
                this.test(this.otherFirefox, ua) ||
                this.test(this.otherChrome, ua),
        };

        this.seven_inch = this.test(this.sevenInch, ua);

        this.any = this.apple.device || this.android.device || this.windows.device || this.other.device || this.seven_inch;
        this.phone = this.apple.phone || this.android.phone || this.windows.phone;
        this.tablet = this.apple.tablet || this.android.tablet || this.windows.tablet;
        this.isApple = this.apple.device;
    }
}
