import {inject, Injectable} from "@angular/core";
import {Router} from "@angular/router";
import {Redirect} from "./interface";
import {DOCUMENT} from "@angular/common";

@Injectable({providedIn: "root"})
export class RedirectBrowserService implements Redirect {
    private router = inject(Router);
    private DOCUMENT = inject(DOCUMENT);

    async _301(url: string): Promise<boolean> {
        this.DOCUMENT.location.replace(url);
        return true;
    }

    async _404() {
        return await this.router.navigateByUrl("/404", {skipLocationChange: true});
    }

    async _410() {
        return await this.router.navigateByUrl("/410", {skipLocationChange: true});
    }
}
