<header>
    <app-ui-smart-banner class="smart-banner"></app-ui-smart-banner>
    <ma-layout-header-floating class="shadow-card"></ma-layout-header-floating>
    <div class="wrapper">
        <ma-layout-header></ma-layout-header>
    </div>
</header>
<main role="main">
    <div id="wrapper">
        <router-outlet />
    </div>
</main>

<footer>
    <ma-ui-alphabet class="footer-alphabet" />
    <ma-ui-sms-installation />
    <ma-layout-footer />
    <ma-ui-floating-block-bottom></ma-ui-floating-block-bottom>
    <app-ui-scroll-top></app-ui-scroll-top>
</footer>
