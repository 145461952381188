<svg-icon class="icon" [src]="'icons/svg/personal-account.svg'" />
@if (isAuth()) {
    <a class="link link_dashed">
        Профиль
        @if (isMenu) {
            <app-menu-drop [list]="menuList" (click)="isMenu = !isMenu" />
        }
    </a>
} @else {
    <a rel="nofollow" class="link link_underline" routerLink="/auth/login">Вход</a>
}
