import {ChangeDetectionStrategy, Component, Input} from "@angular/core";

@Component({
    selector: "ma-elem-item",
    standalone: true,
    templateUrl: "./elem-item.component.html",
    styleUrls: ["./elem-item.component.less"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ElemItemComponent {
    @Input() name: string = "";
}
