import {Injectable} from "@angular/core";
import {LazyScriptService} from "@common/core/service/lazy-script";
import {ConfigService} from "@common/core/service/config";
import {PlatformService} from "@common/core/service/platform";
import {BehaviorSubject} from "rxjs/internal/BehaviorSubject";

declare const gtag: any;

@Injectable({providedIn: "root"})
export class GoogleService {
    private config;

    /**
     * true - загружен уже
     * null - не загружен еще
     *
     * @private
     */
    public downloadScriptStatus: BehaviorSubject<any> = new BehaviorSubject(null);

    constructor(
        private _lazyScript: LazyScriptService,
        private _platform: PlatformService,
        private _config: ConfigService,
    ) {
        this.config = this._config.get("google");
    }

    public run() {
        this._lazyScript.load("https://www.googletagmanager.com/gtag/js?id=" + this.config["id"], true).then((res) => {
            if (res) {
                gtag("js", new Date());
                gtag("config", this.config["id"]);
                this.downloadScriptStatus.next(true);
            }
            return res;
        });
    }
}
