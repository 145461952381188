import {ChangeDetectionStrategy, Component, Input} from "@angular/core";
import {ImageLineListComponent} from "@shared/module_v2/ui/image-line/image-line.component";
import {ImageLineModule} from "@component/ui/image-line/image-line.module";

@Component({
    selector: "ma-elem-product",
    standalone: true,
    templateUrl: "./elem-product.component.html",
    styleUrls: ["./elem-product.component.less"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [ImageLineModule],
})
export class ElemProductComponent {
    public _img: ImageLineListComponent[];
    @Input() set img(val: string[]) {
        this._img = val.map((v) => {
            return {
                alt: "",
                src: v,
            };
        });
    }
    @Input() name: string = "";
    @Input() groups: string = "";
}
