import {Injectable} from "@angular/core";
import {PlatformService} from "@common/core/service/platform";
import {SizeService} from "@core/service/size/size.service";

@Injectable({
    providedIn: "root",
})
export class LocalStorageService {
    private ls;

    constructor(
        private _platform: PlatformService,
        private _sizeService: SizeService,
    ) {
        if (this._platform.browser) {
            this.ls = localStorage;
        }
    }

    /**
     * Возращает данные по ключу
     *
     * @returns {any[]}
     */
    public get(name: string): string {
        if (this._platform.browser) {
            return this.ls.getItem(name);
        } else {
            return "";
        }
    }

    /**
     * Возращает все данные
     *
     * @returns any
     */
    public getList(): any[] {
        const res: any[] = [];

        for (let i = 0, len = this.ls.length; i < len; i++) {
            const key = this.ls.key(i);
            res[key] = this.ls[key];
        }

        return res;
    }

    /**
     * Запись
     *
     * @param key
     * @param value
     */
    public set(key: string, value: any): void {
        if (this._platform.browser) {
            try {
                if (typeof value === "object") {
                    value = JSON.stringify(value);
                }
                this.ls.setItem(key, value);
            } catch (e) {
                this.removeTalkMeChat();
                this.set(key, value);
                // const scope = new Sentry.Scope();
                // const list = this.getList();
                // const sizeLs =  this._sizeService.calcArray(list);
                // scope.setContext('localStorage', {
                //     'size Mb' : sizeLs.mb,
                //     'size Kb' : sizeLs.kb,
                // });
                // Sentry.captureException(new Error('Ошибка записи localStorage'), () => scope);

                return e;
            }
        }
    }

    /**
     * Обработка данных
     *
     * @param val
     * @returns {any}
     */
    private prepare(val: any): any {
        if (typeof val === "object") {
            try {
                return JSON.parse(val);
            } catch {
                return "";
            }
        }

        return val;
    }

    /**
     * Удаления
     *
     * @param {string} key
     */
    public remove(key: string) {
        this.ls.removeItem(key);
    }

    private removeTalkMeChat() {
        const list = this.getList();
        Object.entries(list).forEach(([key]) => {
            if (/^(Chat\/)/.test(key)) {
                this.remove(key);
            }
        });
    }
}
