import {Inject, Injectable} from "@angular/core";
import {Location} from "@core/service/url/interface";
import {DOCUMENT} from "@angular/common";
import {PlatformService} from "@common/core/service/platform";
import {REQUEST} from "@common/tokens/express.tokens";

@Injectable({
    providedIn: "root",
})
export class Url {
    constructor(
        @Inject(DOCUMENT) public _document: Document,
        @Inject(REQUEST) private request: any,
        private _platform: PlatformService,
    ) {}

    /**
     * Возращает Location
     *
     * @param {string} _url
     * @returns {Location}
     */
    parser(_url: string): Location {
        const link = this._document.createElement("a");
        link.href = _url;
        return {
            protocol: link.protocol,
            host: link.host,
            hostname: link.hostname,
            port: link.port,
            pathname: link.pathname,
            search: link.search,
            hash: link.hash,
        };
    }

    public pathname(): string {
        let pathname: string = "";
        if (this._platform.browser) {
            pathname = document.location.pathname;
        } else {
            pathname = this.request["url"];
        }
        return pathname.replace(/\?.*/, "");
    }

    public search(): string {
        let pathname: string = "";
        if (this._platform.browser) {
            pathname = document.location.search;
        } else {
            pathname = this.request._parsedUrl.search;
        }
        return pathname;
    }
}
