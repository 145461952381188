import {inject, Injectable} from "@angular/core";
import {ConfigService} from "@common/core/service/config";

@Injectable({
    providedIn: "root",
})
export class LoggerService {
    private isDebug: boolean = false;
    private _config = inject(ConfigService);

    constructor() {
        this.isDebug = <boolean>this._config.get("debug");
    }

    /**
     * Выводит в консоль данные error
     *
     * @param title названия
     * @param data данные
     */
    public error(title: string, data: any): void {
        if (this.isDebug) {
            console.error(title + ": ", data);
        }
    }

    /**
     * Выводит в консоль данные warn
     *
     * @param title названия
     * @param data данные
     */
    public warn(title: string, data: any): void {
        if (this.isDebug) {
            console.warn(title + ": ", data);
        }
    }

    /**
     * Выводит в консоль данные info
     *
     * @param title названия
     * @param data данные
     */
    public info(title: string, data: any): void {
        if (this.isDebug) {
            /* eslint-disable */
            console.info(title + ":", data);
        }
    }

    /**
     * Выводит в консоль данные debug
     *
     * @param title названия
     * @param data данные
     */
    public debug(title: string, data: any): void {
        if (this.isDebug) {
            console.debug(title + ": ", data);
        }
    }

    /**
     * Выводит в консоль данные log
     *
     * @param title названия
     * @param data данные
     */
    public log(title: string, data: any): void {
        if (this.isDebug) {
            console.log(title + ": ", data);
        }
    }
}
