import {inject, Injectable} from "@angular/core";
import {Cookie} from "@core/service/cookie";
import {PlatformService} from "../../../core/service/platform";
import {ORIGIN_HEADERS} from "@shared/injection-token/tokens";
import {DOCUMENT} from "@angular/common";

@Injectable({
    providedIn: "root",
})
export class RefererService {
    private readonly NAME_STORAGE = "ma_referer";
    private cookie = inject(Cookie);
    private platform = inject(PlatformService);
    private originHeaders = inject(ORIGIN_HEADERS);
    private document: Document = inject(DOCUMENT);

    load() {
        if (this.platform.server) {
            if (this.originHeaders["referer"]) {
                this.set(this.originHeaders["referer"]);
            }
        }
    }

    public get() {
        let val = "";
        if (this.getStorage()) {
            val = this.getStorage();
        } else {
            val = this.document.referrer;
        }
        return val;
    }

    private set(value: string) {
        this.setStorage(value);
    }

    public delete() {
        this.setStorage("");
    }

    /**
     * Значение
     *
     * @returns {string}
     */
    protected getStorage(): string {
        return this.cookie.getItem(this.NAME_STORAGE);
    }

    /**
     * Запись
     *
     * @returns {string}
     */
    protected setStorage(value: string) {
        if (value) {
            this.cookie.setItem(this.NAME_STORAGE, value);
        } else {
            this.cookie.removeItem(this.NAME_STORAGE);
        }
    }
}
