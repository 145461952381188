import {ChangeDetectionStrategy, Component, ViewChild, ViewContainerRef} from "@angular/core";
import {OrdersDialogService} from "@shared/service/api/orders/dialog";
import {UserService} from "@common/core/service/user";
import {Router} from "@angular/router";
import {LazyLoadedService} from "@core/service/lazy-loaded/lazy-loaded.service";

@Component({
    selector: "app-my-order",
    templateUrl: "./my-order.component.html",
    styleUrls: ["./my-order.component.less"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MyOrderComponent {
    @ViewChild("container", {read: ViewContainerRef}) container: ViewContainerRef;

    constructor(
        private _ordersDialogService: OrdersDialogService,
        private _userService: UserService,
        private _router: Router,
        private _lazyLoadedService: LazyLoadedService,
    ) {}

    onClick() {
        if (this._userService.isAuth) {
            this._router.navigateByUrl("/lk/orders");
        } else {
            this._router.navigateByUrl("/check");
        }
    }
}
