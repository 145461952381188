import {Injectable} from "@angular/core";
import {MobileService} from "@core/service/mobile";

@Injectable({
    providedIn: "root",
})
export class DeviceTypeService {

    constructor(private _mobile: MobileService) {
    }

    /**
     * тип устройства
     * 41 — desktop site, 42 — mobile site
     *
     * @returns {number}
     */
    get() {
        if (this._mobile.isMobile()) {
            return 42;
        }
        return 41;
    }
}
