import {inject, Injectable, OnDestroy, signal} from "@angular/core";
import {SearchSuggestionsService} from "@shared/service/api/search/suggestions/suggestions.service";
import {SearchQueriesService} from "@shared/service/api/search/queries/queries.service";
import {forkJoin, of, switchMap, tap, Subscription, Subject} from "rxjs";
import {SearchHeaderHintsData, SearchHeaderHintsStatus} from "@component/ui-request/search-header-hints/search-header-hints";
import {SearchHeaderHintsHistoryService} from "@component/ui-request/search-header-hints/search-header-hints-history.service";
import {map, takeUntil} from "rxjs/operators";

@Injectable({
    providedIn: "any",
})
export class SearchHeaderHintsService implements OnDestroy {
    public data = signal<SearchHeaderHintsData>({
        status: "start",
        history: signal<string[]>([]),
        queries: [],
        products: [],
        groups: [],
        tags: [],
    });

    private searchHeaderHintsHistoryService = inject(SearchHeaderHintsHistoryService);
    private searchSuggestionsService = inject(SearchSuggestionsService);
    private searchQueriesService = inject(SearchQueriesService);
    private cancelSuggestions$ = new Subject<void>();
    private queriesAndHistory$: Subscription;

    changedQuery(query?: string) {
        if (this.data().status === "start") {
            this.fetchData(query);
            return;
        }

        if (query?.length < 3) {
            this.cancelSuggestions$.next();
            this.setStatus("empty");
            return;
        }

        this.fetchData(query);
    }

    /**
     * Запрос подсказок
     *
     * @param query
     */
    getSuggestions(query: string): void {
        this.cancelSuggestions$.next();

        // Set up the new subscription
        of(query)
            .pipe(
                switchMap((query) => this.searchSuggestionsService.get({query}).pipe(takeUntil(this.cancelSuggestions$))),
                tap(({groups, tags, products}) => {
                    this.data.set({
                        ...this.data(),
                        groups: groups || [],
                        products: products || [],
                        tags: tags || [],
                    });

                    const hasResults = (products?.length || 0) > 0 || (tags?.length || 0) > 0 || (groups?.length || 0) > 0;
                    this.setStatus(hasResults ? "fount" : "not_fount");
                }),
            )
            .subscribe();
    }

    /**
     * Запрос для получение данных
     *
     * @param query
     */
    fetchData(query?: string) {
        if (query?.length > 2) {
            this.getSuggestions(query);
        } else {
            this.cancelSuggestions$.next();
            this.setStatus("empty");
        }
    }

    /**
     * Получение подсказок + истории, вызываться должен 1 раз
     *
     * @param setStatus
     */
    getQueriesAndHistory(setStatus: boolean = true) {
        this.queriesAndHistory$ = forkJoin({
            queries: this.searchQueriesService.get({count: 5}).pipe(map((value) => value.list)),
            history: this.searchHeaderHintsHistoryService.get(),
        })
            .pipe(
                tap(({queries}) => {
                    this.data.set({
                        ...this.data(),
                        ...{
                            queries,
                            history: this.searchHeaderHintsHistoryService.history,
                        },
                    });
                    if (setStatus) {
                        this.setStatus("empty");
                    }
                }),
            )
            .subscribe();
    }

    /**
     * Установка статуса загрузки
     * @param status
     */
    setStatus(status: SearchHeaderHintsStatus) {
        this.data.set({...this.data(), ...{status}});
    }

    clear() {
        this.searchHeaderHintsHistoryService.clear();
    }

    ngOnDestroy() {
        this.cancelSuggestions$.next();
        this.cancelSuggestions$.complete();

        if (this.queriesAndHistory$) {
            this.queriesAndHistory$.unsubscribe();
        }
    }
}
