import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from "@angular/core";
import {ResizeService} from "@core/service/resize/resize.service";
import {Subscription} from "rxjs/internal/Subscription";
import {PlatformService} from "@common/core/service/platform";

/**
 * @deprecated use ngImg
 */
@Component({
    selector: "app-html-image",
    templateUrl: "./image.component.html",
    styleUrls: ["./image.component.less"],
})
export class ImageComponent implements OnInit, OnDestroy {
    @Input() alt: string = "";
    @Input() title: string = undefined;

    @Input() srcMobile: string = "";
    @Input() srcDesktop: string = "";
    @Input() sizeMobile: number[] = [];
    @Input() sizeDesktop: number[] = [];

    @Input() lazy: boolean = false;
    @Input() itemprop: boolean = false;

    @Input() imageSrc: string = "";

    @Output() clickOutput = new EventEmitter<string>();

    private _resizeService$: Subscription;

    public srcM: string = "";
    public srcD: string = "";
    public src: string = "";
    public width: number = null;
    public height: number = null;
    public default: string = "";

    constructor(
        private _resizeService: ResizeService,
        private _platform: PlatformService,
        private _cdr: ChangeDetectorRef,
    ) {}

    ngOnInit(): void {
        this.srcM = this.srcMobile;
        this.srcD = this.srcDesktop;
        this._resizeService$ = this._resizeService.resize$.subscribe((res) => {
            if (res.mobile) {
                this.width = this.sizeMobile[0];
                this.height = this.sizeMobile[1];
                this.src = this.srcMobile;
            } else {
                this.width = this.sizeDesktop[0];
                this.height = this.sizeDesktop[1];
                this.src = this.srcDesktop;
            }

            this._cdr.detectChanges();
        });
    }

    onClick() {
        this.clickOutput.emit();
    }

    ngOnDestroy() {
        if (this._resizeService$) {
            this._resizeService$.unsubscribe();
        }
    }
}
