import {Directive, inject, TemplateRef, ViewContainerRef} from "@angular/core";
import {PlatformService} from "../../core/service/platform";

/**
 * @description Directive for displaying content only on the browser
 */
@Directive({
    selector: "[maIfPlatformBrowser]",
    standalone: true,
})
export class IfPlatformBrowserDirective {
    private platform = inject(PlatformService);
    private templateRef = inject(TemplateRef);
    private viewContainer = inject(ViewContainerRef);

    constructor() {
        if (this.platform.browser) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        }
    }
}
